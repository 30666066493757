$primary: #7f0d0d;
$secondary: #FCF4F4;
$base: #4b5563;
$success: #2daba0;

.ant-layout {
  .ant-layout-header {
    background: white;
  }
}

.ant-table {
  .ant-table-thead > tr > th {
    background: white;
  }
}

.ant-tabs-tab {
  &.ant-tabs-tab-active .ant-tabs-tab-btn,
  &:hover {
    color: #7f0d0d !important;
  }
}

.ant-tabs-ink-bar {
  background-color: #7f0d0d !important;
}

.ant-input {
  border-radius: 5px !important;
}

.ant-switch {
  background-color: #808080 !important;

  &.ant-switch-checked {
    background-color: green !important;
  }
}

.ant-input-group {
  .ant-input {
    border-radius: 0 5px 5px 0 !important;
  }

  .ant-input-group-addon {
    border-radius: 5px 0 0 5px !important;
    color: $base;
    background-color: white !important;
  }
}

.ant-input-affix-wrapper {
  border-radius: 5px !important;

  .ant-input-prefix {
    margin-left: -10px;
  }
}

.ant-picker-range {
  border-radius: 5px;
  padding: 6px 10px !important;

  .ant-picker-range-separator {
    .ant-picker-separator {
      height: 22px;
    }
  }
}

// .ant-select:not(.ant-select-customize-input) .ant-select-selector {
//   position: relative;
//   background-color: #fff;
//   border: 1px solid #d9d9d9;
//   border-radius: 5px !important;
//   transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
// }

.ant-form-item-explain-error {
  color: #ff4d4f;
  font-size: 13px;
}

.ant-btn-primary {
  background-color: $primary !important;
  border-color: $primary !important;
}

.ant-btn-primary:hover {
  opacity: 0.9;
}

.ant-btn-primary[disabled],
.ant-btn-primary[disabled]:active,
.ant-btn-primary[disabled]:focus,
.ant-btn-primary[disabled]:hover {
  color: rgba(0, 0, 0, 0.25) !important;
  border-color: #d9d9d9 !important;
  background: #f5f5f5 !important;
  text-shadow: none;
  box-shadow: none;
}

.ant-btn-success {
  background-color: $success !important;
  border-color: $success !important;
  color: #fff !important;
}

.ant-btn-success:hover {
  opacity: 0.9;
}

.ant-btn-success-outline {
  background-color: #fff !important;
  border-color: $success !important;
  color: $success !important;
}

.ant-btn-success-outline:hover {
  opacity: 0.9;
}

.ant-btn-danger {
  background-color: #fef2f2 !important;
  border-color: #fef2f2 !important;
  color: #dc2626 !important;
  font-weight: 500 !important;
}

.ant-btn-danger:hover {
  opacity: 0.9;
}

.ant-pagination {
  @apply flex order-5;

  .ant-pagination-item-link {
    display: flex !important;
    align-items: center;
    justify-content: center;
  }

  .ant-pagination-total-text {
    @apply mr-auto;
  }

  .ant-pagination-options {
    @apply float-left;
  }
}

.ant-form-item {
  margin: 0 0 14px !important;
}

.ant-form-item-with-help .ant-form-item-explain {
  height: auto;
  min-height: 14px !important;
  opacity: 1;
}

.ant-form-item-label > label.ant-form-item-required::before {
  content: none !important;
}

.ant-form-item-label
  > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::after {
  display: inline-block;
  margin-right: 4px;
  color: #ff4d4f;
  font-size: 14px;
  line-height: 1;
  content: "*";
}

.ant-tag {
  border-radius: 15px !important;
  padding: 1px 10px !important;
}

.square {
  border-radius: 4px !important;
}

.apexcharts-toolbar {
  display: none !important;
}

.custom-button {
  background-color: $primary !important;
  color: white !important;
}

.ant-btn-primary {
  background-color: $primary !important;
}

.ant-btn-success {
  background-color: $primary !important;
}

.ant-menu {
  color: $base;

  .ant-menu-sub.ant-menu-inline > .ant-menu-item {
    padding-left: 58px !important;
  }

  .ant-menu-item {
    padding-left: 30px !important;

    &:hover {
      color: $primary !important;
    }

    .anticon {
      min-width: 24px;
    }
  }

  .ant-menu-submenu {
    .ant-menu-submenu-title .ant-menu-item-icon,
    .ant-menu-submenu-title .anticon {
      min-width: 24px;
    }

    .ant-menu-submenu-title {
      .ant-menu-title-content {
        font-weight: 500;
      }
    }

    .ant-menu-sub {
      .ant-menu-item-selected {
        color: $primary;
      }

      .ant-menu-item {
        &:hover {
          color: $primary;
        }
      }
    }
  }

  .ant-menu-submenu-selected {
    color: $primary;
  }

  .ant-menu-submenu-inline {
    &:hover {
      .ant-menu-submenu-title {
        .ant-menu-item-icon,
        .ant-menu-title-content,
        .ant-menu-submenu-arrow {
          color: $primary;
        }
      }
    }
  }

  .ant-menu-submenu-active > .ant-menu-submenu-title,
  .ant-menu-submenu-selected > .ant-menu-submenu-title {
    color: $primary !important;
  }

  .ant-menu-submenu:hover > .ant-menu-submenu-title {
    color: $primary !important;
  }

  .ant-menu-item-selected {
    color: $primary !important;
    background-color: $secondary !important;
  }

  .ant-menu a:hover {
    color: $primary !important;
  }

  .ant-menu-item-selected a:hover,
  .ant-menu-item-selected:hover {
    color: white !important;
  }
}

.ant-table-pagination-custom {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.ant-table-pagination-custom .ant-pagination-item {
  border-radius: 0;
  margin-right: 4px;
}

.ant-table-pagination-custom .ant-pagination-item-active {
  background-color: #f6f6f6;
  border-color: transparent;
}

.ant-table-pagination-custom .ant-pagination-item-active a {
  color: #1d2939;
}

.ant-table-pagination-custom .ant-pagination-prev,
.ant-table-pagination-custom .ant-pagination-next {
  margin: 0 4px;
}

.ant-table-pagination-total {
  margin-right: 16px;
}

.ant-upload-drag-container {
  background-color: white !important;
}

.ant-upload {
  background-color: white !important;
}
