@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:ital,wght@0,200..800;1,200..800&display=swap");
@import "./assets//styles/_customUI.scss";
@import "leaflet/dist/leaflet.css";

* {
  font-family: "Plus Jakarta Sans", sans-serif;
}
html,
body {
  overflow: hidden;
}

h1 {
  @apply text-xl;
}
h2 {
  @apply text-lg;
}
h3 {
  font-size: 18px;
}
h4 {
  font-size: 16px;
}
p {
  font-size: 14px;
}

.custom-switch .ant-switch-checked {
  background-color: #4caf50; /* Warna ketika switch aktif (ON) */
}

.custom-switch .ant-switch {
  background-color: #f44336; /* Warna ketika switch tidak aktif (OFF) */
}

.bg-custom {
  background: #fff6f6;
}

.content-section {
  .header {
    @apply flex justify-between items-center mb-7;
    h2 {
      @apply text-2xl text-black mb-0;
      font-weight: 600;
    }
    &-title {
      @apply text-black text-lg;
      @apply font-bold;
    }
  }
  .body {
    @apply bg-white shadow-md rounded-md w-full px-8 py-4;
  }
  .body-with-footer {
    @apply bg-white shadow-md rounded-md w-full;
    .body-content {
      @apply p-8;
    }
    .footer {
      @apply px-8 pt-4 pb-4 flex justify-end gap-x-3 border-t-2;
    }
  }
}

.font-header {
  @apply text-baseColor text-xl;
  font-weight: 600;
}

.custom-tag {
  margin-right: 8px;
  padding: 1px 10px;
  font-size: 12px;
  line-height: 20px;
  white-space: nowrap;
  border-radius: 15px;
  opacity: 1;
  transition: all 0.3s;
  &-blue {
    color: #0284c7;
    background: #e0f2fe;
  }
  &-green {
    color: #166534;
    background: #dcfce7;
  }
  &-orange {
    color: #9a3412;
    background: #ffedd5;
  }
  &-red {
    color: #991b1b;
    background: #fee2e2;
  }
  &-purple {
    color: #6b21a8;
    background: #f3e8ff;
  }
  &-yellow {
    color: #854d0e;
    background: #fef9c3;
  }
  &-gray {
    color: #4b5563;
    background: #cbd5e0;
  }
  &-default {
    color: #1f2937;
    background: #e5e7eb;
  }
}

.custom-checkbox {
  .ant-checkbox-inner {
    border-color: #7f0d0d !important;
    background-color: #fff !important;
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #7f0d0d !important;
    border-color: #7f0d0d !important;
  }

  .ant-checkbox-checked::after {
    border-color: #7f0d0d !important;
  }

  .ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner,
  .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: #7f0d0d !important;
  }

  .ant-checkbox-checked:hover .ant-checkbox-inner {
    background-color: #7f0d0d !important;
  }
}

/* Custom styles for the Ant Design table header */
// .ant-table-header th {
//   color: white; /* Change sorting icon color */
//   background-color: #7f0d0d;
// }

// /* Optional: Customize sorting icons */
// .ant-table-column-sorters {
//   color: white; /* Change sorting icon color */
//   background-color: #7f0d0d;
// }

// /* Optional: Change hover effects */
// .ant-table-header:hover {
//   background-color: #600000; /* Darker shade on hover */
// }
